<template>
  <div class="container">
    <h2>商家协议</h2>
    <pre style="line-height:28px">
        {{content}}
    </pre>
  </div>
</template>

<script>
import { getSystemInfo } from "@/api/api";
export default {
  data() {
    return {
      type: this.$route.query.type,
      content: ""
    };
  },
  mounted() {
    const that = this;
    getSystemInfo().then(res => {
      if (res.result) {
        that.content = res.data[that.type];
      }
    });
  }
};
</script>

<style scoped>
.container {
  padding: 40px 160px;
  background: #fff;
}
h2 {
  margin-bottom: 30px;
  font-weight: bold;
  text-align: center;
}
</style>